@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Inter", "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #faf9f6;
  background-image: url('/src/assets/bgtop.svg');
  background-repeat: no-repeat;
  background-position: top left; 
}

.font-blue {
  color: #1877f2 !important;
}
footer a:hover{
color: #1877f2;
}
.hover:hover{
  color: #1877f2;
}
.font-red {
  color: #e51e25;
}
.font-newblack {
  color: #191825;
}
.inter {
  font-family: "Inter", sans-serif;
}
.poppins {
  font-family: "Poppins", sans-serif;
}
